import './index.css?ver=1';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import type { ReactElement } from 'react';
import type { IRouteItemProp } from 'core/interfaces/base';
import SettingProvider from './core/context/SettingContext';
import './App.css?ver=1';
import { routeItems } from './core/routes';
import { addTrackingCode } from "./core/constants/scriptFunc";
import { AuthProvider } from './core/context/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import loadingImg from './assets/images/loading.png';

const CustomerRouter = React.lazy(() => import('pages/backend/router/customer-router/customer-router'));
const PartnerRouter = React.lazy(() => import('pages/backend/router/partner-router/partner-router'));
const ErrorPage = React.lazy(() => import('pages/ErrorPage/ErrorPage'));

const queryClient = new QueryClient()

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <SettingProvider>
            <>
              {addTrackingCode(process.env.REACT_APP_ENV)}
              <Routes>
                {
                  routeItems.map((item:IRouteItemProp):ReactElement => (
                    <Route
                      path={item.path}
                      key={item.id}
                      element={(
                        <Suspense
                          fallback={(
                            <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-10 bg-[#FFFFFFB3]">
                              <img
                                src={loadingImg}
                                alt='loading...'
                                width={200}
                                height={200}
                              />
                            </div>
                          )}
                        >{item.element}</Suspense>
                      )}
                    />
                  ))
                }
                <Route path="/customer" element={<CustomerRouter />} />
                <Route path="/partner/*" element={<PartnerRouter />} />
                <Route path="*" element={<ErrorPage message=' The page you are looking for could not be found.' />} />
              </Routes>
            </>
          </SettingProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}
